import React, { useCallback, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { sendPasswordResetEmail } from 'firebase/auth';

import { Country } from '@/constants/Countries';
import useSubscription from '@/hooks/account/useSubscription';
import useAccountContext from '@/hooks/context/useAccountContext';
import useSnackbarContext from '@/hooks/context/useSnackbarContext';
import { UpdateAccountModel } from '@/models/Account';
import AccountAPI from '@/network/AccountAPI';
import { handleApiError } from '@/utility/api';

import { auth } from '../../firebaseSetup';
import ButtonComponent from '../utility/microcomponents/Button';
import { CountrySelector } from '../utility/microcomponents/CountrySelector';
import DialogModal from '../utility/modals/DialogModal';

const AccountsPage = () => {
  const { t } = useTranslation();

  const { account, accountId, refetchAccount, logoutUser } = useAccountContext();
  const { dispatchSnackbar } = useSnackbarContext();

  const { isSubscribed, status } = useSubscription();

  const [cantDeleteModalOpen, setCantDeleteModalOpen] = useState<boolean>(false);

  const [updatingUser, setUpdatingUser] = useState<boolean>(false);
  const [deletingUser, setDeletingUser] = useState<boolean>(false);

  const [dialogOpen, setDialogOpen] = useState<boolean>(false);

  const signInMethods = useMemo(() => {
    return [
      {
        name: 'COMMON.PASSWORD',
        enabled: auth.currentUser?.providerData.some((item) => item.providerId === 'password'),
      },
      {
        name: 'BRAND.GOOGLE',
        enabled: auth.currentUser?.providerData.some((item) => item.providerId === 'google.com'),
      },
      {
        name: 'BRAND.FACEBOOK',
        enabled: auth.currentUser?.providerData.some((item) => item.providerId === 'facebook.com'),
      },
      {
        name: 'BRAND.APPLE',
        enabled: auth.currentUser?.providerData.some((item) => item.providerId === 'apple.com'),
      },
    ];
  }, []);

  const defaultValues = {
    firstName: account?.contact?.firstName,
    lastName: account?.contact?.lastName,
    email: account?.contact?.email,
    countryCode: account?.countryCode,
  };

  const { register, watch } = useForm<UpdateAccountModel>({ defaultValues });
  const formState = watch();

  const updateUser = async () => {
    setUpdatingUser(true);
    try {
      if (!accountId) return;

      const data = {
        firstName: formState.firstName,
        lastName: formState.lastName,
        email: formState.email,
        countryCode: formState.countryCode,
      };
      await AccountAPI.updateAccount({ accountId, data });
      await refetchAccount();
      dispatchSnackbar({
        type: 'OPEN_SNACKBAR',
        payload: {
          message: 'User updated successfully',
          type: 'success',
        },
      });
    } catch (error: unknown) {
      handleApiError({ error, dispatchSnackbar });
    } finally {
      setUpdatingUser(false);
    }
  };

  const handleChangePassword = useCallback(() => {
    if (!account) return;

    sendPasswordResetEmail(auth, account?.contact?.email).then(() => {
      dispatchSnackbar({
        type: 'OPEN_SNACKBAR',
        payload: {
          message: 'Password reset email sent, check your inbox',
          type: 'success',
        },
      });
    });
  }, [account, dispatchSnackbar]);

  const deleteUser = async () => {
    setDeletingUser(true);
    try {
      if (!accountId) return;

      await AccountAPI.deleteAccount({ accountId });
      dispatchSnackbar({
        type: 'OPEN_SNACKBAR',
        payload: {
          message: 'Account successfully deleted',
          type: 'success',
        },
      });
      await logoutUser();
    } catch (error: unknown) {
      handleApiError({ error, dispatchSnackbar });
    } finally {
      setDialogOpen(false);
      setDeletingUser(false);
    }
  };

  const confirmDelete = () => {
    if (isSubscribed && status !== 'ended' && status !== 'ending') {
      setCantDeleteModalOpen(true);
      return;
    }
    setDialogOpen(true);
  };

  return (
    <>
      <Dialog
        open={dialogOpen}
        onClose={() => {
          setDialogOpen(false);
        }}
      >
        <DialogTitle>{t('COMMON.DELETE-ACCOUNT')}</DialogTitle>
        <DialogContent>{t('COMMON.DELETE-ACCOUNT-CONFIRM-MESSAGE')}</DialogContent>
        <DialogActions>
          <Button
            disabled={deletingUser}
            onClick={() => {
              setDialogOpen(false);
            }}
          >
            {t('COMMON.CLOSE')}
          </Button>
          <Button
            disabled={deletingUser}
            data-testid="confirm-delete-button"
            onClick={() => {
              deleteUser();
            }}
          >
            {deletingUser ? <CircularProgress size={16} /> : t('COMMON.CONFIRM')}
          </Button>
        </DialogActions>
      </Dialog>
      <DialogModal
        open={cantDeleteModalOpen}
        output={() => {
          setCantDeleteModalOpen(false);
        }}
        isInfoDialog={true}
        title={'SUBSCRIPTION.SETTINGS.CANT-DELETE-ACCOUNT'}
        content={t('SUBSCRIPTION.SETTINGS.CANT-DELETE-ACCOUNT-DESCRIPTION')}
      />
      <div className="card w50p-lg-up w100p-lg-down">
        <div className="d-flex gap20">
          <label className="flex-w50p">
            <p>{t('COMMON.FIRST-NAME')}</p>
            <input {...register('firstName')} placeholder="John" data-testid="firstname"></input>
          </label>
          <label className="flex-w50p">
            <p>{t('COMMON.LAST-NAME')}</p>
            <input {...register('lastName')} placeholder="Smith" data-testid="lastname"></input>
          </label>
        </div>
        <div className="d-flex gap20 mt20">
          <label className="flex-w50p">
            <p>{t('COMMON.EMAIL-ADDRESS')}</p>
            <input
              {...register('email')}
              value={account?.contact.email}
              placeholder="John@Smith.com"
              disabled={true}
              data-testid="email"
            ></input>
          </label>
          <label className="flex-w50p">
            <p>{t('COMMON.PASSWORD')}</p>
            <Button className="w100p m0 border-btn mt2" onClick={handleChangePassword}>
              {t('COMMON.CHANGE-PASSWORD')}
            </Button>
          </label>
        </div>
        <div className="w100p">
          <p className="mt16 mb8">Location</p>
          <CountrySelector
            preselectedCountry={account?.countryCode}
            outputCountry={(country: Country) => {
              formState.countryCode = country.abbr;
            }}
          />
        </div>
        <p className="mt20 mb10">{t('COMMON.SIGN-IN-METHODS')}</p>
        <div className="d-flex gap20 flex-wrap">
          {signInMethods.map((item, index) => (
            <div key={index} className="card-inner flex-grow p12">
              <p className="small">{t(item.name)}</p>
              <p className="small text-faded">{item.enabled ? 'Enabled' : 'Disabled'}</p>
            </div>
          ))}
        </div>
        <ButtonComponent
          isCustom
          className="card-inner mt20"
          dataTestId="delete-account-button"
          onClick={() => {
            confirmDelete();
          }}
        >
          <p className="text-error cursor-pointer">{t('COMMON.PERMANENTLY-DELETE-ACCOUNT')}</p>
        </ButtonComponent>
      </div>
      <Button className="m0 btn-white mt20" onClick={updateUser} disabled={updatingUser}>
        {updatingUser ? <CircularProgress size={16} /> : t('COMMON.SAVE-CHANGES')}
      </Button>
    </>
  );
};

export default AccountsPage;
