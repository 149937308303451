import React, { useMemo, useState } from 'react';

import { Button, CircularProgress } from '@mui/material';

import useAccountContext from '@/hooks/context/useAccountContext';
import useSnackbarContext from '@/hooks/context/useSnackbarContext';
import MetaAPI from '@/network/MetaAPI';
import TikTokAPI from '@/network/TikTokAPI';
import { handleApiError } from '@/utility/api';

import FacebookLoginButton from '../utility/buttons/FacebookLoginButton';
import TikTokLoginButton from '../utility/buttons/TikTokLoginButton';

const ConnectedAccounts = () => {
  const { account, accountId, refetchAccount } = useAccountContext();
  const { dispatchSnackbar } = useSnackbarContext();

  const [isTikTokLoading, setIsTikTokLoading] = useState<boolean>(false);
  const [isMetaLoading, setIsMetaLoading] = useState<boolean>(false);

  const isTikTok = useMemo(
    () => account?.accessTokens?.filter((item) => item.platform === 'tiktok')[0]?.accessToken,
    [account?.accessTokens]
  );
  const isMeta = useMemo(
    () => account?.accessTokens?.filter((item) => item.platform === 'meta')[0]?.accessToken,
    [account?.accessTokens]
  );

  const logoutOfMeta = async () => {
    setIsMetaLoading(true);
    try {
      if (!accountId) return;

      await MetaAPI.logoutOfMeta({ accountId });
      await refetchAccount();
      dispatchSnackbar({
        type: 'OPEN_SNACKBAR',
        payload: {
          type: 'success',
          message: `Successfully logged out of Meta`,
        },
      });
    } catch (error: unknown) {
      handleApiError({ error, dispatchSnackbar });
    } finally {
      setIsMetaLoading(false);
    }
  };

  const logoutOfTikTok = async () => {
    setIsTikTokLoading(true);
    try {
      if (!accountId) return;

      await TikTokAPI.logoutOfTikTok({ accountId });
      await refetchAccount();
      dispatchSnackbar({
        type: 'OPEN_SNACKBAR',
        payload: {
          type: 'success',
          message: `Successfully logged out of TikTok`,
        },
      });
    } catch (error: unknown) {
      handleApiError({ error, dispatchSnackbar });
    } finally {
      setIsTikTokLoading(false);
    }
  };

  return (
    <div className="d-flex gap20 flex-wrap">
      <div className="card min-w320 w100p-lg-down">
        <div className="d-flex gap8 h24">
          <img src="/images/logos/meta-logo-white.svg" alt="" />

          {!isMeta ? <h5>Connect your Meta account</h5> : <h5>Meta account</h5>}
        </div>
        {!isMetaLoading && isMeta && (
          <div className="d-flex gap8">
            <div className="mt8">
              <p className="small">Meta account connected</p>
              <p className="text-faded small pt0">View details in social ads flow</p>
            </div>
            <Button
              className="ml-auto mb0 mr0"
              onClick={() => {
                logoutOfMeta();
              }}
            >
              Disconnect
            </Button>
          </div>
        )}
        {!isMetaLoading && !isMeta && <FacebookLoginButton isWide={true} />}
        {isMetaLoading && (
          <Button className="w100p ml0 mb0" disabled={true}>
            <CircularProgress size={16} />
          </Button>
        )}
      </div>
      <div className="card min-w320 w100p-lg-down">
        <div className="d-flex gap8 h24">
          <img src="/images/logos/tiktok-logo-white.svg" alt="" />
          {!isTikTok ? <h5>Connect your TikTok account</h5> : <h5>TikTok account</h5>}
        </div>
        {!isTikTokLoading && isTikTok && (
          <div className="d-flex gap8">
            <div className="mt8">
              <p className="small">TikTok account connected</p>
              <p className="text-faded small pt0">View details in social ads flow</p>
            </div>
            <Button
              className="ml-auto mb0 mr0"
              onClick={() => {
                logoutOfTikTok();
              }}
            >
              Disconnect
            </Button>
          </div>
        )}
        {!isTikTokLoading && !isTikTok && <TikTokLoginButton isWide={true} />}
        {isTikTokLoading && (
          <Button className="w100p ml0 mb0" disabled={true}>
            <CircularProgress size={16} />
          </Button>
        )}
      </div>
    </div>
  );
};

export default ConnectedAccounts;
